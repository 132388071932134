<template>
	<div class="p-grid p-mt-4" v-if="car">
    <div class="p-col-12 p-md-12 p-lg-6">
      <Panel header="Общие данные">
        <table>
          <tbody style="text-align: left">
            <tr>
              <th class="p-p-1">{{ $t('Owner') }}:</th>
              <td class="p-p-1">
                <div class="p-d-flex p-ai-center p-flex-wrap p-ml-3" v-if="car.owner" >
                  <CustomerStatusHistoryButton :customerStatus="car.owner.customerStatus"
                                               :customerId="car.owner.id"
                                               @change-customer-status="changeCustomerStatus"/>
                  <router-link class="p-d-flex p-ai-center p-mr-1" :to="{ path: `/customers/${car.owner.id}` }" target="_blank">
                    <span v-if="car.owner.type === constants.userTypes.legal_entity && car.owner.company_name">{{ car.owner.company_name }} </span>
                    <span v-else>{{ car.owner.first_name }} {{ car.owner.last_name }} </span>
<!--                    <span style="position: relative; top: -1px; left: 2px; transform: scale(0.45)" :class="'flag&#45;&#45;datatable flag flag-' + car.owner.flag_code"></span>-->
                  </router-link>
                  <div class="customer-data-flag-wrapper">
                    <span :class="'flag flag-' + car.owner.flag_code"></span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Make') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ car.make?.name }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Model') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ car.model?.name }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Plate number') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ car.plate_number }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('VIN number') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ car.vin }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Year') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ car.year }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Power') }} ({{ (settings.measurements.power) }}):</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ car.power }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Comment') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ car.comment }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Created') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="car.created_at">{{ formatDate(car.created_at) }}<span v-if="car.creator">, {{ car.creator.first_name }} {{ car.creator.last_name }}</span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js'
import constants from '@/constants'
import formatMixins from '@/mixins/formatMixins'
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";

export default {
  mixins: [ formatMixins ],
  components: { CustomerStatusHistoryButton },
  emits: ['change-customer-status'],
  props: {
    car: Object,
  },
	data() {
		return {
      carId: null,
      isLoading: true,
      carData: null,
      settings,
      constants
		}
	},
  methods: {
    changeCustomerStatus(newStatus, customerId) {
      this.$emit('change-customer-status', newStatus, customerId)
    }
  }
}
</script>